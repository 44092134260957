import React, { useState } from 'react';
import { GiftIcon, HomeIcon, Star, TreePine, Sparkles, Snowflake } from 'lucide-react';

/**
 * Reviewed and Refined by Senior UX Designer and Senior Software Architect
 *
 * Changes Incorporated:
 * 1. **Endings and Replay Logic:**
 *    - After finding an ending (but not all 9), the "Play Again" button now returns you to the 'start' scene instead of 'title'.
 *    - If all 9 endings have been found, a celebration modal allows returning to the 'title' scene or going to the home page (root of the app).
 *
 * 2. **Improved UX Guidance:**
 *    - Clear instructions in the celebration modal indicating what the user can do next (return to title or home).
 *    - Consistent labeling: "Play Again" is shown only when not all endings are found. Once all endings are found, "Back to Title" and "Return Home" options are provided.
 *
 * 3. **Enhanced Button and Modal Layout:**
 *    - Differentiated the replay/continue buttons visually and by adding slight hover and scale transitions.
 *    - Ensured the modal clearly shows the completion state and provides clear, visually distinct actions.
 *
 * 4. **Consistent Data Structure and Error Handling:**
 *    - Scenes are all defined in the `scenes` object, including 'title'.
 *    - Graceful fallback if a non-existent scene key is encountered.
 *
 * 5. **Maintaining Festive Aesthetic:**
 *    - Kept the festive style, cursive fonts, icons, and cheerful messages intact for a polished, fun, and consistent holiday theme.
 */

const ChristmasTree = () => (
  <svg viewBox="0 0 100 120" className="w-16 h-16">
    <path d="M50 10 L30 40 L70 40 Z" fill="#0C5C0C"/>
    <path d="M50 25 L25 60 L75 60 Z" fill="#0C5C0C"/>
    <path d="M50 45 L20 85 L80 85 Z" fill="#0C5C0C"/>
    <rect x="45" y="85" width="10" height="15" fill="#8B4513"/>
    <circle cx="50" cy="30" r="3" fill="#FFD700"/>
    <circle cx="40" cy="50" r="3" fill="#FF0000"/>
    <circle cx="60" cy="45" r="3" fill="#87CEEB"/>
    <circle cx="45" cy="65" r="3" fill="#FF0000"/>
    <circle cx="55" cy="70" r="3" fill="#FFD700"/>
  </svg>
);

const Santa = () => (
  <svg viewBox="0 0 100 100" className="w-16 h-16">
    <circle cx="50" cy="30" r="20" fill="#FFE4E1"/>
    <path d="M30 35 Q50 80 70 35" fill="#FFFFFF"/>
    <circle cx="43" cy="25" r="4" fill="#000"/>
    <circle cx="57" cy="25" r="4" fill="#000"/>
    <path d="M45 35 Q50 40 55 35" fill="#000"/>
    <path d="M20 30 Q50 90 80 30" fill="#FFFFFF"/>
    <rect x="35" y="10" width="30" height="10" fill="#FF0000"/>
    <circle cx="65" cy="10" r="5" fill="#FFF"/>
  </svg>
);

const Ornament = () => (
  <svg viewBox="0 0 50 60" className="w-12 h-12">
    <path d="M25 0 L25 10" stroke="#FFD700" strokeWidth="2"/>
    <circle cx="25" cy="30" r="20" fill="#FF0000"/>
    <path d="M15 20 Q25 50 35 20" fill="#FF0000"/>
  </svg>
);

export default function ChristmasGame() {
  const [currentScene, setCurrentScene] = useState('title');
  const [endings, setEndings] = useState(new Set());
  const [showCompletionModal, setShowCompletionModal] = useState(false);

  const scenes = {
    title: {
      text: "Ho Ho Ho! Welcome to a magical Christmas Eve adventure! Can you discover all 9 special endings and spread holiday cheer?",
      choices: [
        {
          text: "Begin Your Christmas Adventure!",
          next: "start",
          icon: <GiftIcon className="mr-2 h-6 w-6" />
        }
      ],
      isTitle: true
    },
    start: {
      text: "You're 10 years old and it's the most exciting night of the year - Christmas Eve! You've been helping Mom and Dad with presents all month, but you just realized you forgot to wrap the special gifts you bought with your allowance money! Even worse, you hear shuffling downstairs... Could Santa have come early? Or is it your little sister trying to peek at her presents?",
      choices: [
        { text: "Tiptoe downstairs to investigate", next: "investigate" },
        { text: "Quickly start wrapping presents", next: "wrapping" },
        { text: "Hide under your bed", next: "hide" }
      ]
    },
    investigate: {
      text: "You carefully make your way down the stairs, trying to avoid the creaky third step. As you peer around the corner, you see... your cat batting around ornaments from the Christmas tree while wearing a tiny Santa hat!",
      choices: [
        { text: "Try to save the ornaments", next: "save_ornaments" },
        { text: "Take photos for social media", next: "photos" },
        { text: "Join your cat in the chaos", next: "join_chaos" }
      ]
    },
    wrapping: {
      text: "You look around your messy bedroom for anything to wrap presents with. All you can find is: yesterday's newspaper from Dad's recycling bin, your old homework assignments (including that A+ spelling test!), and... leftover Halloween paper from your costume party!",
      choices: [
        { text: "Use the Halloween paper", next: "halloween" },
        { text: "Get creative with newspaper", next: "newspaper" },
        { text: "Use homework assignments", next: "homework" }
      ]
    },
    hide: {
      text: "You dive under your bed (where you usually hide when Mom tells you to clean your room) and encounter an unexpected surprise!",
      choices: [
        { text: "An elf checking your list", next: "elf" },
        { text: "Your sibling hiding presents", next: "sibling" },
        { text: "Last year's missing cookies", next: "cookies" }
      ]
    },
    save_ornaments: {
      text: "After a wild chase around the tree, you end up tangled in tinsel, covered in glitter, and somehow wearing the cat's Santa hat. Your family finds you like this in the morning.",
      ending: "THE GLITTER GUARDIAN",
      endingDetails: "Mom can't stop laughing at the photos, and you're still finding glitter in your hair when school resumes. At least the ornaments survived... mostly!"
    },
    photos: {
      text: "Your cat is surprisingly photogenic! The videos go viral, and by Christmas morning, your cat has become an internet sensation.",
      ending: "PAWFLUENCER",
      endingDetails: "Your cat now has more followers than your entire class combined! Your parents let you use some cat food sponsorship money for a new bike."
    },
    join_chaos: {
      text: "You and your cat have a late-night ornament-batting party. Santa finds you both asleep under the tree.",
      ending: "SANTA'S SURPRISE",
      endingDetails: "You wake up to a note from Santa: 'Best Christmas Eve entertainment I've had in centuries! Extra presents for both of you.'"
    },
    halloween: {
      text: "You commit to the spooky Christmas theme, adding Santa hats to ghost patterns.",
      ending: "NIGHTMARE BEFORE CHRISTMAS",
      endingDetails: "Your family is confused but impressed. Your cousin wants Halloween paper on all gifts moving forward!"
    },
    newspaper: {
      text: "You turn newspaper into elaborate origami wrapping, with bow ties from the comics section.",
      ending: "EXTRA! EXTRA!",
      endingDetails: "Your family is so impressed they make newspaper wrapping a new tradition. Now you're in charge of all wrapping, every year!"
    },
    homework: {
      text: "You wrap everything in old assignments, showcasing your A+ test on top.",
      ending: "ACADEMIC ACHIEVEMENT",
      endingDetails: "Your parents get so distracted by your good grades they barely notice the gifts. You earn extra Christmas money for creativity!"
    },
    elf: {
      text: "You meet Fred, a junior elf from Quality Control, under your bed, reviewing your Christmas wish list.",
      ending: "NORTH POLE BUREAUCRACY",
      endingDetails: "You help Fred finish paperwork. He grants you 'same-day gift wrapping service' from the North Pole!"
    },
    sibling: {
      text: "You both scream quietly, then realize you're hiding from each other!",
      ending: "SIBLING SOLIDARITY",
      endingDetails: "You team up to wrap presents, creating a secret midnight wrapping club that meets every Christmas Eve."
    },
    cookies: {
      text: "You find last year's cookies preserved under the bed, now home to mice wearing tiny paper crowns.",
      ending: "ROYAL RODENTS",
      endingDetails: "The mice help you wrap presents in exchange for this year's cookies. Tiny paw prints decorate every gift!"
    }
  };

  const currentSceneData = scenes[currentScene];

  // Graceful fallback if currentScene doesn't exist in scenes
  if (!currentSceneData) {
    return (
      <div className="min-h-screen bg-green-700 p-4">
        <div className="max-w-2xl mx-auto text-white text-center">
          <p>Oops! Something went wrong. Resetting your game...</p>
          <button
            className="mt-4 bg-red-600 hover:bg-red-700 text-white border-2 border-green-400 px-4 py-2 rounded transition transform hover:scale-105"
            onClick={() => setCurrentScene('title')}
          >
            Back to Title
          </button>
        </div>
      </div>
    );
  }

  const isEnding = 'ending' in currentSceneData;

  const handleChoice = (nextScene) => {
    setCurrentScene(nextScene);
    if (scenes[nextScene]?.ending) {
      const newEndings = new Set([...endings, scenes[nextScene].ending]);
      setEndings(newEndings);
      // If all 9 endings found, show completion modal
      if (newEndings.size === 9) {
        setShowCompletionModal(true);
      }
    }
  };

  // If not all endings are found, "Play Again" should go back to start
  // If all endings are found (9), the modal will allow going back to title or home
  const handleReplay = () => {
    if (endings.size < 9) {
      setCurrentScene('start');
    } else {
      // If all endings found, they will have buttons in the modal to go back to title or home
      // For now, if we needed a fallback here, we could just set to title, but it's not needed
      setCurrentScene('title');
    }
  };

  const handleReturnToTitle = () => {
    setShowCompletionModal(false);
    setCurrentScene('title');
  };

  const handleReturnHome = () => {
    // This simulates returning to the root of the app. In a real app, you might use a router:
    // e.g., `navigate('/')` if using React Router.
    // Here we reset everything and simulate a home redirect.
    setShowCompletionModal(false);
    setCurrentScene('title');
    setEndings(new Set());
    // If a real home page exists, you'd redirect to it here.
  };

  return (
    <div className="min-h-screen bg-green-700 p-4">
      <div className="max-w-2xl mx-auto">
        <div className="mb-4 bg-green-800 border-2 border-red-500 rounded-lg p-4">
          <div className="border-b border-red-500 pb-4">
            <h2 className="text-2xl font-bold text-center text-red-500 flex items-center justify-center gap-2 font-[cursive]">
              <TreePine className="h-6 w-6 text-red-500" />
              The Great Christmas Eve Adventure
              <TreePine className="h-6 w-6 text-red-500" />
            </h2>
          </div>
          <div className="pt-4">
            {!isEnding && currentSceneData.isTitle && (
              <div className="text-center space-y-6 bg-green-900 p-6 rounded-lg border-2 border-red-500">
                <h3 className="text-xl text-white font-semibold">Game made by Santa himself!</h3>
                <p className="text-lg text-white">{currentSceneData.text}</p>
                <div className="flex justify-center">
                  <Snowflake className="h-6 w-6 text-red-500 animate-spin" />
                </div>
              </div>
            )}

            {!currentSceneData.isTitle && !isEnding && (
              <p className="text-lg mb-4 text-white">{currentSceneData.text}</p>
            )}

            {isEnding && (
              <div className="mt-4 p-4 bg-green-900 rounded-lg border-2 border-red-500">
                <h3 className="text-xl font-bold text-red-500 mb-2 flex items-center gap-2">
                  <Star className="h-5 w-5 text-yellow-400" />
                  Ending: {currentSceneData.ending}
                </h3>
                <p className="text-white">{currentSceneData.endingDetails}</p>
              </div>
            )}

            <div className="space-y-2 mt-6">
              {currentSceneData.choices?.map((choice, index) => (
                <button
                  key={index}
                  onClick={() => handleChoice(choice.next)}
                  className="w-full bg-green-900 hover:bg-red-900 text-white border-red-500 border rounded px-4 py-2 flex items-center transition-colors"
                >
                  {choice.icon || null}
                  {choice.text}
                </button>
              ))}
              {isEnding && endings.size < 9 && (
                <button
                  onClick={handleReplay}
                  className="w-full mt-4 bg-red-600 hover:bg-red-700 text-white border-2 border-green-400 px-4 py-2 rounded flex items-center justify-center gap-2 transition transform hover:scale-105"
                >
                  <HomeIcon className="h-4 w-4" />
                  Play Again
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="bg-green-800 border-2 border-red-500 rounded-lg p-4">
          <div className="border-b border-red-500 pb-4">
            <h3 className="text-lg flex items-center gap-2 text-red-500 font-semibold font-[cursive]">
              <GiftIcon className="h-5 w-5 text-red-500" />
              Endings Discovered: {endings.size}/9
            </h3>
          </div>
          <div className="pt-4">
            <div className="grid grid-cols-3 gap-2">
              {Array.from(endings).map((ending, index) => (
                <div key={index} className="p-2 bg-green-900 rounded text-sm text-white border border-red-500 flex items-center gap-1">
                  <Star className="h-4 w-4 text-yellow-400" />
                  <span>{ending}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {showCompletionModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-green-800 border-4 border-red-500 max-w-2xl w-full mx-4 p-6 rounded relative overflow-hidden text-center">
            <h2 className="text-3xl font-bold text-center text-red-500 mb-4 font-[cursive]">
              HO HO HO! Merry Christmas!
            </h2>

            <div className="flex justify-center gap-4 mb-6">
              <ChristmasTree />
              <Santa />
              <Ornament />
            </div>

            <div className="bg-green-900 p-6 rounded-lg border-2 border-red-500">
              <h3 className="text-2xl font-bold text-white mb-4 flex items-center justify-center gap-2 font-[cursive]">
                <Sparkles className="h-6 w-6 text-yellow-400" />
                You've Found All The Christmas Magic!
                <Sparkles className="h-6 w-6 text-yellow-400" />
              </h3>

              <p className="text-lg text-white mb-4">
                You've discovered all 9 magical endings and spread joy to everyone!  
                Where would you like to go next?
              </p>

              <div className="flex flex-col sm:flex-row gap-4 justify-center mt-6">
                <button
                  onClick={handleReturnToTitle}
                  className="bg-red-600 hover:bg-red-700 text-white border-2 border-green-400 px-4 py-2 rounded transition transform hover:scale-105"
                >
                  Back to Title
                </button>
                <button
                  onClick={handleReturnHome}
                  className="bg-red-600 hover:bg-red-700 text-white border-2 border-green-400 px-4 py-2 rounded transition transform hover:scale-105"
                >
                  Return Home
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
