import React, { useState } from 'react';
import './App.css';

// Import your game components here
import ExampleGame from './games/ExampleGame';
import ChristmasGame from './games/ChristmasGame';

// Add new games to this array as you create them
const GAMES = [
  {
    id: 'christmas-eve',
    title: 'The Great Christmas Eve Adventure',
    description: 'Embark on a magical journey through Christmas Eve! Help Santa deliver presents and experience the holiday spirit.',
    thumbnail: '/images/christmas-eve-thumbnail.svg',
    component: ChristmasGame,
  },
  {
    id: 'example',
    title: 'Example Game',
    description: 'A simple example game to get you started',
    thumbnail: '/images/example-thumbnail.svg',
    component: ExampleGame,
  },
];

function App() {
  const [selectedGame, setSelectedGame] = useState(null);

  return (
    <div className="App">
      <header className="App-header">
        <h1>Fun Time Frenzy</h1>
      </header>
      <main>
        {!selectedGame ? (
          <div className="game-grid">
            {GAMES.map((game) => (
              <div
                key={game.id}
                className="game-card"
                onClick={() => setSelectedGame(game)}
              >
                <img src={game.thumbnail} alt={game.title} />
                <h2>{game.title}</h2>
                <p>{game.description}</p>
              </div>
            ))}
          </div>
        ) : (
          <selectedGame.component
            onBack={() => setSelectedGame(null)}
          />
        )}
      </main>
    </div>
  );
}

export default App;
