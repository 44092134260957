import React, { useState } from 'react';
import './ExampleGame.css';

function ExampleGame() {
  const [score, setScore] = useState(0);

  const handleClick = () => {
    setScore(prev => prev + 1);
  };

  return (
    <div className="example-game">
      <h3>Example Clicker Game</h3>
      <p>Score: {score}</p>
      <button onClick={handleClick}>Click me!</button>
    </div>
  );
}

export default ExampleGame;
